import iconEmail from '../assets/img/icon-envelope-email.svg';
import iconArrowOne from '../assets/img/icon-arrow-1.svg';

function Header() {
  return (
    <header className="header">
      <div className="logo-block">
        <a href="/" className="logo" title="Ryan Matsudaira">Ryan Matsudaira</a>
      </div>
      <div className="email-block">
        <ul>
          <li><img src={iconArrowOne} className="arrow" alt=""/><a href="mailto:ryan@matsudaira.io" title="Reach out to me" target="_blank" rel="noreferrer"><img src={iconEmail} className="icon-email" alt="Email me"></img></a></li>
        </ul>
      </div>
    </header>
  )
}

export default Header