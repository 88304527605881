import iconArrowThree from '../assets/img/icon-arrow-3.svg';
import iconUnderline from '../assets/img/icon-underline.svg';
import iconSmileContact from '../assets/img/icon-smile-contact.svg';
import { useForm } from '@formspree/react';

function Form() {
  const [state, handleSubmit, reset] = useForm('xovqenjz');
  if (state.succeeded) {
    return ( 
      <section className="contact">
        <h1>Let's have a quick chat.<img src={iconUnderline} className="icon-underline" alt=""/></h1>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <div className="contact-alert">
              <img src={iconSmileContact} className="icon-smile-contact" alt=""/>
              <p>Thanks for reaching out.<br/>I'm looking forward to our chat together!</p>
              <button onClick={reset}>Me too!</button>
            </div>
          </fieldset>
        </form>
      </section>
    )
  }
  return (
    <section className="contact">
      <h1>Let's have a quick chat.<img src={iconUnderline} className="icon-underline" alt=""/></h1>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <input type="text" name="fname" placeholder="Name"></input>
          <input type="text" name="email" placeholder="Email"></input>
          <textarea name="message" placeholder="What would you like to chat about?" rows="6"></textarea>
          <button type="submit" disabled={state.submitting}><img src={iconArrowThree} className="arrow" alt=""/>Let's Talk</button>
        </fieldset>
      </form>
    </section>
  )
}

export default Form