import textSkills from '../assets/img/text-skills.svg';
import iconSwirl from '../assets/img/icon-swirl.svg';
import iconBright from '../assets/img/icon-bright.svg';

function Knowledge() {
  return (
    <section className="knowledge">
        <span class="lead-heading"><img src={textSkills} alt="Knowledge"></img></span>
        <div className="content-left">
          <h2>Highlights</h2>
          <ul>
            <li>
              <h3>Technical:</h3> <p>HTML, CSS, SASS, JavaScript, React, Vue, TypeScript, PHP, Twig</p>
            </li>
            <li>
              <h3>Toolset:</h3> <p>Git, BunnyCDN, Cloudflare, Wordpress, Elementor, Bricks, Photoshop, Figma</p>
            </li>
            <li>
              <h3>UxD:</h3> <p>Responsive Design, Research, A/B Testing, Mood Boards, User Personas, Flow Diagrams, Style Guides, Wireframes, Prototyping, Compositions</p>
            </li>
          </ul>
          <img src={iconSwirl} className="icon-swirl" alt=""/>
        </div>
        <div className="content-right">
            <h1><img src={iconBright} className="icon-bright" alt=""/>Two Decades of Experience.</h1>
            <p>With nearly two decades in the industry, I've grown into a seasoned professional who has worked as a Client Success Manager, Interaction Design (IxD) Consultant, and Front-End Developer. Collectively, I bring a wealth of experience to every project. In my role as a <strong>Client Success Manager</strong>, I've honed the art of building and maintaining client relationships, ensuring that business goals align with client satisfaction, turning clients into long-term partners. As an <strong>IxD Consultant</strong>, I've developed my expertise in crafting intuitive and engaging user experiences that blend aesthetics with functionality, advocating for usability from an empathetic standpoint. With my <strong>front-end development</strong> skills, I've successfully translated designs into reality, employing the latest technologies to ensure interfaces are not only visually appealing but also highly interactive and responsive.</p>
            
            <p>This trifecta of skills positions me as a unique professional that can oversee a project from inception to execution, ensuring that every digital product is not just a tool but a delightful experience. My friendly but professional approach facilitates collaboration across teams, which I believe allows me to be an invaluable asset in creating solutions that are both technically sound and user-centric.</p>
            
            <p>Let's have a chat together and see where I can snugly fit into your team or next project.</p>
        </div>
    </section>
  )
}

export default Knowledge