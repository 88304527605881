import Hero from './Hero';
import Knowledge from './Knowledge';
import Form from './Form';

function MainStructure() {
  return (
    <main>
        <Hero/>
        <Knowledge/>
        <Form/>
    </main>
  )
}

export default MainStructure