import textHello from '../assets/img/text-hello.svg';
import iconSmile from '../assets/img/icon-smile.svg';
import iconHeart from '../assets/img/icon-heart.svg';


function Hero() {
  return (
    <section className="hero">
        <span class="lead-heading"><img src={textHello} alt="Hello"/></span>
        <h1>Client Success Manager <img className="icon-heart" src={iconHeart} alt="Client success manager"/></h1>
        <p>For years I've played an integral role in fostering and nurturing relationships between companies and their clients. I'm dedicated to ensuring your clients not only achieve their desired outcomes but also feel valued and understood throughout their journey with your company. With a blend of professional expertise and a genuinely friendly demeanor, I position myself as a bridge connecting client needs with your company's solutions. I'm proactive in communication, always ready to listen, and adept at anticipating client needs before they arise - while keeping expectations in-check, providing strategic guidance, and delivering personalized service.</p>
        <h2>IxD Consultant</h2>
        <p>As an Interaction Design (IxD) Consultant, I become a creative force behind ensuring that digital products are not only functional but also delightful to use. With a keen eye for detail and a deep understanding of user psychology, I can craft experiences that resonate with users on an intuitive level. As an IxD Consultant, I enjoy diving into the intricacies of user behavior, advocating for the end-user in every design decision, and blending aesthetic appeal with usability. I don't just design interfaces; I design interactions that feel natural, engaging, and effortless.</p>
        <h3>Front-End Developer</h3>
        <p>As a Front-End Developer, some may percieve my role as the artist and architect of the digital world, specializing in creating the visual and interactive elements of websites and applications that users directly interact with. I seamlessly combine coding skills with a flair for design, ensuring that every interaction is smooth, responsive, and engaging. With my toolbox filled with HTML, CSS, JavaScript, and a variety of frameworks, I bring designs to life, making sure that what you see on your screen is not only beautiful but also functional across all devices. Beyond the code, I advocate for user experience, constantly tweaking and refining to enhance performance and accessibility. My work is truly about creating that seamless bridge between user intent and digital response, making the complex appear simple and the ordinary, extraordinary.</p>
        <img src={iconSmile} className="icon-smile" alt="Smile"></img>
    </section>
  )
}

export default Hero